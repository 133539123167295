import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import RecentLinks from "../../RecentLinks";
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

import './Link.scss';
export default (({ link, onLinkClick, editable, categoryId, index, onLinkDeleteButtonClick }) => {

    const [css, setCss] = useState();

    useEffect(() => {
        const newCss = editable ? { cursor: 'move', ...delays[index % delays.length] } : { cursor: 'pointer' };
        setCss(newCss);
    }, [editable])


    const handleDeleteButtonClick = () => {
        if (onLinkDeleteButtonClick)
            onLinkDeleteButtonClick(link);

    }


    return <Col xs={12} sm={6} md={3} lg={2} className="p-1 text-center" onClick={() => onLinkClick(link)} key={link.id}>
        {editable && <button className={`btn btn-sm btn-danger btn-icon-only rounded-circle`} type="button" style={styles.deleteIcon} onClick={(e) => {
            e.stopPropagation();
            handleDeleteButtonClick()
        }
        }>
            <span className="btn-inner--icon"><i className="fa fa-times fa-2x"></i></span>
        </button>}
      
        <div className={`p-3 text-center ${editable ? 'editable-link' : 'link'}`} style={{ ...styles.link, ...css }}>
            <div className="d-flex align-items-center justify-content-center text-center" style={styles.imageContainer}>
                <img src={`https://westminsterintranet.blob.core.windows.net/images/${link.id}.png`} alt="" style={styles.image} />
            </div>
            <div className="d-flex align-items-center justify-content-center" style={styles.textContainer}>
                <p className="card-text font-weight-bold small text-center" style={styles.text}>{link.name}</p>
            </div>
        </div>
    </Col>
})

const styles = {
    imageContainer: {
        maxHeight: 80,
        height: 80
    },
    image: {
        maxWidth: 60,
        maxHeight: 60
    },
    link: {
        minHeight: 160
    },
    text: {
        maxHeight: 42
    },
    textContainer: {
        maxHeight: 42,

        minHeight: 42
    },
    deleteIcon: {
        zIndex: 1000,
        position: 'absolute',
        top: 12,
        right: 12,
        cursor: 'pointer',
        border: '3px solid white'
    }
}


const delays = [{ animationDelay: '-.75s', animationDuration: '.25s' },
{ animationDelay: '-.5s', animationDuration: '.3s' },
{ animationDelay: '-.05s', animationDuration: '.27s' },
{ animationDelay: '-.2s', animationDuration: '.33s' },
{ animationDelay: '-.31s', animationDuration: '.24s' },
{ animationDelay: '-.15s', animationDuration: '.25s' },
{ animationDelay: '-.2s', animationDuration: '.22s' },
{ animationDelay: '-.3s', animationDuration: '.28s' },
{ animationDelay: '-.22s', animationDuration: '.3s' }];