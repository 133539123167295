import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Link from './Link'
import { ReactSortable } from "react-sortablejs";
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
export default (({ category, onLinkClick, editable, onAddButtonClick, onCategoryDeleteButtonClick, onLinkDeleteButtonClick, isAdmin }) => {


    const [links, setLinks] = useState(category.links);

    useEffect(() => {
        setLinks(category.links);
    }, [category.links]);


    const handleLinkClick = (clickedLink) => {
        if (editable && category.canEdit) return;
        onLinkClick(clickedLink, category.id);
    }

    const handleLinkDeleteButtonClick = (clickedLink) => {

        if (!editable) return;
        onLinkDeleteButtonClick(clickedLink, category.id);
    }

    const handleSort = () => {
        var orderedLinks = [];
        links.forEach(x => {
            orderedLinks.push(x.id);
        });


        fetch(`/api/links/${category.id}`, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderedLinks)
        }).then(function (response) {

        });
    }

    return <><Row className="mb-3 mt-3">
        <Col xs={12}>
            <h3 className="heading float-left"><b>{category.name}</b></h3>
            <TransitionGroup>
                {editable && <CSSTransition
                    key={'css'}
                    timeout={500}
                    classNames="fadein"
                >
                    <div className="float-right">
                        <button className="btn btn-sm btn-primary" type="button" onClick={() => onAddButtonClick(category.id)}><i className="fa fa-plus"></i></button>

                        {isAdmin && <button className="btn btn-sm btn-danger" type="button" onClick={() => onCategoryDeleteButtonClick(category)}><i className="fa fa-trash"></i></button>}
                    </div></CSSTransition>}
            </TransitionGroup>

        </Col>
    </Row>
        {editable && category.canEdit ? <ReactSortable list={links} className="row mb-5 editable-links" setList={setLinks} onEnd={handleSort}>

            {links.map((link, index) => (
                <Link key={link.id} link={link} categoryId={category.id} onLinkClick={handleLinkClick} editable={editable && category.canEdit} index={index} onLinkDeleteButtonClick={handleLinkDeleteButtonClick} />
            ))}
        </ReactSortable>
            : <Row className="mb-5">
                {links.map((link, index) => (
                    <Link key={link.id} link={link} categoryId={category.id} onLinkClick={handleLinkClick} editable={editable && category.canEdit} index={index} />
                ))}
            </Row>
        }
    </>
})



const styles = {

    deleteIcon: {
        zIndex: 1000,
        position: 'absolute',
        top: 12,
        right: 12,
        cursor: 'pointer'
    }
}

