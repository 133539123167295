/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, Suspense } from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";
// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footers/Footer.js";
import Category from "../components/Links/Category.js";
import Skeleton from "react-loading-skeleton";
import Contacts from "../components/Contacts/Contacts.js";
const AddLinkModal = React.lazy(() => import("../components/Links/AddLinkModal"));
const AddCategoryModal = React.lazy(() => import("../components/Links/AddCategoryModal"));
let isStandalone = false;

export default props => {
    const [me, setMe] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editCategoryId, setEditCategoryId] = useState();
    const [showAddCategoryModal, setShowCategoryModal] = useState(false);
    const [requiresLogin, setRequiresLogin] = useState(false);

    try {
        isStandalone = window.self === window.top;
    } catch (e) {
        isStandalone = false;
    }

    const onMessage = (event) => {
        let url = `${window.location.protocol}//${window.location.hostname}`
        if (window.location.port && !isNaN(window.location.port) && window.location.port !== 443)
            url += `:${window.location.port}`;

        if (event.origin !== url) return;
        if (event.data === `login`) {
            setRequiresLogin(false);
            getData();
        }
    }

    const redirectToLogin = () => {
        const random = Math.random() * 10000000;
        const redirectUrl = `/auth/login/${random}`;
        if (!isStandalone) {
            setRequiresLogin(true);
            var loginWindow = window.open('', '_login', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            loginWindow.document.write('Redirecting to Sign In provider...');
            loginWindow.location.href = redirectUrl;
         
            window.addEventListener('message', onMessage, false);
        } else
            window.location.href = redirectUrl;
    }

    const getData = async () => {
        const headers = {
            "Content-Type": "application/json"
        };
        if (!isStandalone) {
            headers[`x-seqta-embed`] = `1`;
        }
        fetch("/api/me", {
            method: "GET",
            headers
        })
            .then(function (response) {
                if (response.status === 401) {
                    redirectToLogin();
                    return;
                }


                if (response.status !== 200) {
                    return;
                }

                // Examine the text in the response
                response.json().then(function (data) {
                    setMe(data);
                });
            })
            .catch(function (err) { });
    };

    useEffect(() => {
        setTimeout(() => getData(), 0);
    }, []);

    const renderLoader = [0, 1, 2].map(ic => (
        <div key={ic}>
            <>
                <Row className="mb-3 mt-3">
                    <Col xs={12}>
                        <Skeleton height={26} width={300} />
                    </Col>
                </Row>
                <Row className="mb-5">
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                        <Col xs="2" className="mb-3" key={i}>
                            <Skeleton height={160} width={"100%"} />
                        </Col>
                    ))}
                </Row>
            </>
        </div>
    ));

    const welcomeMessage = () => {
        var today = new Date();
        var curHr = today.getHours();
        var timeMessage = "";
        if (curHr < 12) {
            timeMessage = "Good Morning";
        } else if (curHr < 18) {
            timeMessage = "Good Afternoon";
        } else {
            timeMessage = "Good Evening";
        }

        return timeMessage;
    };

    const handleLinkClick = (link, categoryId) => {
        window.open(link.url);

        window.gtag('event', 'Click', {
            'event_category': 'Link',
            'event_label': link.url,
            'value': 1
        });

    };

    const handleLinkDeleteButtonClick = (link, categoryId) => {

        fetch(`/api/links/${categoryId}/${link.id}`, {
            method: 'delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(json => {
                if (json) {
                    getData();
                }
            });
    }
    const handleCategoryDeleteButtonClick = (category) => {

        const confirmed = window.confirm(`Are you sure you want to delete ${category.name}?`);
        if (!confirmed) return;
        fetch(`/api/category/${category.id}`, {
            method: 'delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(json => {
                if (json) {
                    getData();
                }
            });
    }

    const toggleEditMode = () => {
        if (!me) return;
        if (!me.isAdmin) {
            setEditMode(false);
            return;
        }

        setEditMode(prevState => !prevState);
    }

    const handleModalClose = (refresh) => {
        setEditCategoryId(null);
        if (refresh)
            getData();
    }

    const handleAddCategoryModalClose = (refresh) => {
        setShowCategoryModal(false);
        if (refresh) getData();
    }

    const handleAddButtonClick = (categoryId) => {
        setEditCategoryId(categoryId);
    }

    const containerProps = {};
    if (!isStandalone)
        containerProps.fluid = "fluid";

    const buttons = () => {

        if (me && me.isAdmin) {
            return <><div className="mb-5">
                <button className="btn btn-primary" type="button" onClick={toggleEditMode}>
                    <span className="btn-inner--icon">{editMode ? <><i className="fa fa-check  mr-2"></i>Finish Editing Page</> : <><i className="fa fa-edit  mr-2"></i> Edit Page</>}</span>
                </button>

                {me.isAdmin && <button className="btn btn-secondary" type="button" onClick={() => setShowCategoryModal(true)}>
                    <span className="btn-inner--icon"><i className="fa fa-plus mr-2"></i> Add Category</span>
                </button>}
            </div>
                <Suspense fallback={<></>}><AddLinkModal show={editCategoryId != null} handleModalClose={handleModalClose} categoryId={editCategoryId} /></Suspense>
                {me.isAdmin && <Suspense fallback={<></>}><AddCategoryModal show={showAddCategoryModal} handleModalClose={handleAddCategoryModalClose} /></Suspense>}
            </>;
        }

        return null;
}

    return (
        <>
            {isStandalone && <Navbar />}
            <main className="profile-page">
                {isStandalone && <section className="section-profile-cover section-shaped my-0">

                    <div className="shape shape-style-1 shape-default alpha-4">
                        <img
                            alt="..."
                            src="https://westminsterintranet.blob.core.windows.net/assets/wyvern.svg"
                        />
                    </div>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            x="0"
                            y="0"
                            viewBox="0 0 2560 100"
                            preserveAspectRatio="none"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>
                </section>}
                <section className={isStandalone ? "section" : ""}>
                    <Container {...containerProps}>
                        <Card className={isStandalone ? "card-profile shadow  mt--400 mh-100" : "border-0"}>
                            {requiresLogin && <div className="text-center p-5" ><button className="btn btn-primary" type="button" onClick={redirectToLogin}>Click here to login to your personalised home page</button></div>}
                            {!requiresLogin && <>
                            <div className="px-4">
                                <>
                                    <div className="mt-3 p-3 text-left">
                                        <Row className="">
                                            <Col lg="9">
                                                {me ? (
                                                    <h3 className="mb-0 novarese">
                                                        {welcomeMessage()}, {me.name}
                                                    </h3>
                                                ) : (
                                                        <Skeleton height={38} width={300} />
                                                    )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-0 p-3 border-top text-left">
                                        {me &&
                                            me.links.map((x) => (
                                                <Category
                                                    editable={editMode}
                                                    key={x.id}
                                                    category={x}
                                                    onLinkClick={handleLinkClick}
                                                    onLinkDeleteButtonClick={handleLinkDeleteButtonClick}
                                                    onAddButtonClick={handleAddButtonClick}
                                                    onCategoryDeleteButtonClick={handleCategoryDeleteButtonClick}
                                                    isAdmin={me.isAdmin}
                                                />
                                            ))}

                                        {!me && renderLoader}
                                    </div>
                                </>
                                </div></>}
                        </Card>
                    </Container>
                </section>
            </main>
            {isStandalone && <Footer buttons={buttons()} />}
            {(me && me.isStaff) && <Contacts />}
                
        </>
    );
};
