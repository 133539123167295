import React, { useEffect, useState, useRef, useCallback } from 'react';
import debounce from 'lodash.debounce';

export default ({ onClose }) => {
    const controller = new AbortController();
    const { signal } = controller;

    const debouncedSearch = useCallback(debounce((term) => doSearch(term), 200));

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);
    const [contacts, setContacts] = useState(null);
    const searchBox = useRef(null);

    useEffect(() => {
        searchBox.current.focus();
    }, []);

    const handleChange = (e) => {
        var term = e.target.value;
        setSearch(term);
        debouncedSearch(term);
    }

    const doSearch = (term) => {
        if (!term || term.length < 3) return;
        setLoading(true);
        var url = `/api/contacts/?q=${term}`
        fetch(url, { signal })
            .then(x => x.json())
            .then(y => { setContacts(y); setLoading(false);})
            .catch(e => {
                if (e.name === "AbortError") {
                    // We know it's been canceled!
                }
            });
    }

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const renderContact = (contact) => {
        return <div style={styles.contact} className="border-bottom p-3 w-100 float-left" key={contact.id}>
            <img src={`/api/contacts/image/${contact.id}`} alt="" style={styles.contactImage} />
            <div className="float-left small" style={{ maxWidth: 250 }}>
                <span className="d-block mb-1"><b style={styles.contactName}>{contact.name}</b></span>
                {contact.position && <span className="d-block mb-1">{contact.position}</span>}
                {contact.emailAddress && <span className="d-block mb-1"><span style={styles.icon}><i className="fa fa-envelope mr-1"></i></span> <a href={`mailto:${contact.emailAddress}`}><small>{contact.emailAddress}</small></a></span>}
                {contact.telephone && <span className="d-block mb-1"><span style={styles.icon}><i className="fa fa-phone mr-1"></i></span> {contact.telephone}</span>}
                {contact.emailAddress && <span className="d-block mb-1"><a href={`https://teams.microsoft.com/l/chat/0/0?users=${contact.emailAddress}`} target="_blank"><span style={styles.icon}><img src={require('../../assets/img/icons/teams.svg')} alt="Chat on Teams" style={styles.teamsIcon} className="mr-1" /></span> <small style={{ textTransform: 'uppercase', fontWeight: 600 }}>Teams</small></a></span>}
            </div>
        </div>
    }

    return <div className="shadow mb-5 bg-white rounded" style={styles.contactSearchContainer}>
        <div className="bg-primary">
            <div className="px-3 py-2 text-white d-flex justify-content-between">
                <h3 className="heading text-white mb-0"><b>Contacts</b></h3>
                <i className="fa fa-times" style={{ marginTop: 2, cursor: 'pointer' }} aria-hidden="true" onClick={onClose} ></i>
            </div>
        </div>
        <div className="px-3 py-2">
            <input type="text" ref={searchBox} autoComplete={`autocomplete_off_${uuidv4()}`} list="autocompleteOff" placeholder="Search..." className={`form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0 p-1`} style={loading ? styles.loading : {}} onChange={handleChange} />
        </div>
        <div className="px-3 py-2" style={styles.contactList}>
            {contacts && contacts.map(c => {
                return <>{renderContact(c)}</>
            })}
            {search && search.length > 2 && contacts && contacts.length === 0 && <div className="w-100 p-3 text-center">No contacts found</div>}
        </div>
    </div>
}

const styles = {
    contactSearchContainer: {
        position: 'fixed',
        bottom: 30,
        right: 20,
        height: 560,
        width: 400,
        maxWidth: '90%',
        zIndex: 900,
        overflow: 'hidden'
    },
    header: {
    },
    icon:
    {
        display: 'inline-block',
        width: 16,
        textAlign: 'center'
    },
    teamsIcon: {
        width: 16,
        height: 15
    },
    searchBox: {},
    contactList: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 463
    },
    contactImage: {
        height: 48,
        width: 48,
        maxHeight: 48,
        maxWidth: 48,
        borderRadius: 24,
        float: 'left',
        marginRight: 16,
        display: 'block'
    },
    contactName: { fontSize: '0.8rem', fontWeight: 600 },
    contactDetails: { fontSize: '0.7rem' },
    contact: { lineHeight: '1.3rem' },
    loading: {
        backgroundImage: "url('https://westminsterintranet.blob.core.windows.net/assets/loader.gif')",
        backgroundSize: "25px 25px",
        backgroundPosition: "right center",
        backgroundRepeat: "no-repeat"
    }
}