/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";


export default ({ buttons }) => {

    return (
        <>
            <footer className=" footer">
                <Container>
                    {buttons &&
                        <Row className=" align-items-center justify-content-md-between">
                            <Col md="12" className="text-center">
                                {buttons}
                            </Col></Row>}

                    <Row className=" align-items-center justify-content-md-between">
                        <Col md="12" className="text-center">
                            <div className=" copyright">
                                © {new Date().getFullYear()}{" "}
                                <a
                                    href="https://westminster.sa.edu.au"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    Westminster School
                  </a>
                  .
                </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}
