import React, { useState } from 'react';
import ContactSearch from './ContactSearch';

export default () => {
    const [showContacts, setShowContacts] = useState(false);

    const toggleContacts = () => {
        setShowContacts(prevState => { return !prevState });
    }

    return <>
        {showContacts && <ContactSearch onClose={() => setShowContacts(false)} />}
        <div style={styles.contactContainer}>
        <button className="btn btn-primary" type="button" onClick={toggleContacts}>
            <span className="btn-inner--icon"><i className="fa fa-users  mr-2"></i> Contacts</span>
        </button>
    </div></>
}



const styles = {
    contactContainer: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 900
    }

}